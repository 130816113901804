var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "mb-1" }, [
        _c(
          "form",
          {
            ref: "formFilter",
            staticClass: "m-0",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitFilter.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-none d-sm-block align-items-center justify-content-left",
                    attrs: { cols: "6", md: "3" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group mb-md-0" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Tag Concurso"),
                        ]),
                        _c(
                          "v-select",
                          {
                            ref: "selectConcurso",
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              placeholder: "Digite o título",
                              options: _vm.optionsTagConcurso,
                            },
                            on: { search: _vm.fetchTagsCategoryConcurso },
                            model: {
                              value: _vm.filters.tag_contest,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "tag_contest", $$v)
                              },
                              expression: "filters.tag_contest",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                on: {
                                  click: function ($event) {
                                    _vm.$refs.selectConcurso.open = false
                                  },
                                },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-none d-sm-block align-items-center justify-content-left",
                    attrs: { cols: "6", md: "3" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group mb-md-0" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Tag Linha do tempo"),
                        ]),
                        _c(
                          "v-select",
                          {
                            ref: "selectLinhadoTempo",
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              placeholder: "Digite o título",
                              options: _vm.optionsTagLinhaDoTempo,
                            },
                            on: { search: _vm.fetchTagsCategoryLinhaDoTempo },
                            model: {
                              value: _vm.filters.tag_timeline,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "tag_timeline", $$v)
                              },
                              expression: "filters.tag_timeline",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                on: {
                                  click: function ($event) {
                                    _vm.$refs.selectLinhadoTempo.open = false
                                  },
                                },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-md-0" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Período de cadastro"),
                      ]),
                      _c("flat-pickr", {
                        staticClass: "form-control",
                        attrs: {
                          config: {
                            mode: "range",
                            altInput: true,
                            dateFormat: "Y-m-d",
                            altFormat: "d/m/Y",
                            locale: "pt",
                          },
                        },
                        model: {
                          value: _vm.filters.rangeDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "rangeDate", $$v)
                          },
                          expression: "filters.rangeDate",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("b-col", { attrs: { cols: "12", md: "2" } }, [
                  _c("div", { staticClass: "form-group mb-md-0" }, [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v("Título do edital"),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.title,
                          expression: "filters.title",
                        },
                      ],
                      staticClass: "form-control",
                      domProps: { value: _vm.filters.title },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.filters, "title", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("b-col", { attrs: { cols: "12", md: "1" } }, [
                  _c(
                    "label",
                    {
                      staticStyle: { visibility: "hidden" },
                      attrs: { for: "" },
                    },
                    [_vm._v("Buscar")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-block",
                      attrs: { type: "submit", disabled: _vm.submitedFilter },
                    },
                    [
                      _vm.submitedFilter
                        ? _c(
                            "div",
                            [
                              _c("b-spinner", {
                                attrs: { label: "Loading...", small: "" },
                              }),
                              _vm._v(" Buscando... "),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c("feather-icon", {
                                staticClass: "cursor-pointer cursor",
                                attrs: { icon: "SearchIcon", size: "16" },
                              }),
                            ],
                            1
                          ),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "m-2" },
            [
              _c(
                "b-form-row",
                { staticClass: "justify-content-between" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-none d-sm-block align-items-center justify-content-left",
                      attrs: { cols: "4", md: "2" },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          label: "title",
                          required: "",
                          options: _vm.perPageOptions,
                          clearable: false,
                        },
                        on: { input: _vm.setPerPageSelected },
                        model: {
                          value: _vm.perPage,
                          callback: function ($$v) {
                            _vm.perPage = $$v
                          },
                          expression: "perPage",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "4", offset: "md-4" } },
                    [
                      _c("b-form-input", {
                        staticClass: "d-inline-block",
                        attrs: {
                          type: "search",
                          placeholder: "Pesquisar...",
                          id: "filterInput",
                        },
                        model: {
                          value: _vm.filter,
                          callback: function ($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
              filter: _vm.filter,
              "filter-included-fields": _vm.filterOn,
            },
            on: { filtered: _vm.onFiltered },
            scopedSlots: _vm._u([
              {
                key: "cell(title)",
                fn: function (data) {
                  return [
                    _c(
                      "a",
                      {
                        attrs: {
                          title: data.item.title,
                          href: data.item.url,
                          target: "_blank",
                          rel: "noopener noreferrer",
                        },
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "ExternalLinkIcon", size: "13" },
                        }),
                        _vm._v(" " + _vm._s(data.item.title) + " "),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(classes)",
                fn: function (data) {
                  return [
                    _c(
                      "b-badge",
                      {
                        staticClass: "text-capitalize",
                        attrs: { variant: "light-dark" },
                      },
                      [_vm._v(" " + _vm._s(data.item.classes) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(extension)",
                fn: function (data) {
                  return [
                    _c("i", {
                      staticClass: "bi",
                      class: "bi-filetype-" + data.item.extension.toLowerCase(),
                      staticStyle: { "font-size": "1.4rem" },
                    }),
                  ]
                },
              },
              {
                key: "cell(created_at)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("datePT")(data.item.created_at, true)) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex-between" },
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer cursor text-danger",
                          attrs: {
                            id: "customer-" + data.item.uuid + "-delete-icon",
                            icon: "TrashIcon",
                            size: "16",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.confirmDelete(data.item.uuid)
                            },
                          },
                        }),
                        _c("feather-icon", {
                          staticClass: "cursor-pointer cursor",
                          attrs: {
                            id: "customer-" + data.item.uuid + "-delete-icon",
                            icon: "EyeIcon",
                            size: "16",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.detailsMaterial(data.item.uuid)
                            },
                          },
                        }),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              id: "customer-" + data.item.uuid + "-edit-icon",
                              to: {
                                name: "notices-edit",
                                params: { uuid: data.item.uuid },
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "cursor-pointer cursor",
                              attrs: { icon: "EditIcon", size: "16" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              "rows-table": _vm.rowsTable,
              "current-page": _vm.currentPage,
              "per-page": _vm.perPage,
              "total-rows": _vm.totalRows,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-create-notice",
          attrs: {
            "modal-class": "modal-create",
            title: "Upload do Material",
            centered: "",
            "hide-footer": "",
            size: "sm",
          },
        },
        [
          _c(
            "b-form",
            {
              ref: "formCreateUser",
              attrs: { enctype: "multipart/form-data" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitUpload.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(" Título "),
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.title,
                          expression: "item.title",
                        },
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.$v.item.title.$error },
                      attrs: { require: "", type: "text" },
                      domProps: { value: _vm.item.title },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.item, "title", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(" Arquivo - "),
                      _c("small", [_vm._v("Limite máximo: 10 MB")]),
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle ml-1",
                      }),
                    ]),
                    _c("input", {
                      ref: "file",
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.$v.item.file.$error },
                      staticStyle: { padding: "0.4rem 0.5rem" },
                      attrs: { type: "file", id: "file" },
                      on: {
                        change: function ($event) {
                          return _vm.handleFileUpload()
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "legend",
                    {
                      staticClass: "mt-2 mb-1",
                      staticStyle: { "font-size": "1.2rem" },
                    },
                    [_vm._v("Tags")]
                  ),
                ]),
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Tag(s) Concurso(s) "),
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                      ]),
                      _c("v-select", {
                        class: {
                          "is-invalid": _vm.$v.item.tags.contest.$error,
                        },
                        attrs: {
                          multiple: "",
                          label: "title",
                          "item-text": "title",
                          "item-value": "code",
                          placeholder: "Digite o título da tag",
                          options: _vm.optionsTagConcurso,
                        },
                        on: { search: _vm.fetchTagsCategoryConcurso },
                        model: {
                          value: _vm.item.tags.contest,
                          callback: function ($$v) {
                            _vm.$set(_vm.item.tags, "contest", $$v)
                          },
                          expression: "item.tags.contest",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Tag(s) Linha do tempo "),
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                      ]),
                      _c("v-select", {
                        class: {
                          "is-invalid": _vm.$v.item.tags.timeline.$error,
                        },
                        attrs: {
                          multiple: "",
                          label: "title",
                          "item-text": "title",
                          "item-value": "code",
                          placeholder: "Digite o título da tag",
                          options: _vm.optionsTagLinhaDoTempo,
                        },
                        on: { search: _vm.fetchTagsCategoryLinhaDoTempo },
                        model: {
                          value: _vm.item.tags.timeline,
                          callback: function ($$v) {
                            _vm.$set(_vm.item.tags, "timeline", $$v)
                          },
                          expression: "item.tags.timeline",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-row justify-content-end mt-2" }, [
                _c(
                  "div",
                  { staticClass: "col-md-3" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "ripple",
                            rawName: "v-ripple.400",
                            value: "rgba(186, 191, 199, 0.15)",
                            expression: "'rgba(186, 191, 199, 0.15)'",
                            modifiers: { 400: true },
                          },
                        ],
                        attrs: {
                          type: "button",
                          variant: "outline-secondary",
                          block: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$bvModal.hide("modal-create-notice")
                          },
                        },
                      },
                      [_vm._v(" Fechar ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "ripple",
                            rawName: "v-ripple.400",
                            value: "rgba(255, 255, 255, 0.15)",
                            expression: "'rgba(255, 255, 255, 0.15)'",
                            modifiers: { 400: true },
                          },
                        ],
                        staticClass: "mr-2",
                        attrs: {
                          variant: "success",
                          type: "submit",
                          block: "",
                          disabled: _vm.submited,
                        },
                      },
                      [
                        _vm.submited
                          ? _c(
                              "div",
                              [
                                _c("b-spinner", {
                                  attrs: { small: "", variant: "light" },
                                }),
                              ],
                              1
                            )
                          : _c("div", [_vm._v("Cadastrar")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.details,
              expression: "details",
            },
          ],
          attrs: {
            id: "modal-details-notice",
            "modal-class": "modal-create",
            title: "Detalhes do material",
            centered: "",
            "hide-footer": "",
          },
        },
        [
          _c("b-row", { staticClass: "form-row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "form-row mb-2 pb-2 border-bottom" }, [
                _vm.details.extension == "png" ||
                _vm.details.extension == "jpg" ||
                _vm.details.extension == "jpeg" ||
                _vm.details.extension == "webp"
                  ? _c(
                      "div",
                      { staticClass: "col-md-3 text-center" },
                      [
                        _c("b-img-lazy", {
                          staticClass: "my-1",
                          attrs: {
                            src: _vm.details.url,
                            rounded: "",
                            fluid: "",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Título")]),
                    _c(
                      "a",
                      { attrs: { href: _vm.details.url, target: "_blank" } },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-0 mr-sm-50",
                          attrs: { icon: "ExternalLinkIcon", size: "16" },
                        }),
                        _vm._v(" " + _vm._s(_vm.details.title) + " "),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "form-group mb-0" }, [
                    _c("strong", [_vm._v("Size")]),
                    _vm._v(": " + _vm._s(_vm.details.size) + "kb - "),
                    _c("strong", [_vm._v("Host")]),
                    _vm._v(": " + _vm._s(_vm.details.driver) + " - "),
                    _c("strong", [_vm._v("Extensão")]),
                    _vm._v(": " + _vm._s(_vm.details.extension) + " "),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("legend", [_vm._v("Tags")]),
                _c(
                  "b-tabs",
                  { staticClass: "mb-0", attrs: { pills: "", fill: "" } },
                  _vm._l(_vm.details.tags, function (tag_category, index) {
                    return _c(
                      "b-tab",
                      {
                        key: index,
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c("feather-icon", {
                                    staticClass: "mr-0 mr-sm-50",
                                    attrs: { icon: "HashIcon", size: "16" },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "d-none d-sm-inline" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            index === "contest"
                                              ? "Concurso"
                                              : ""
                                          ) +
                                          " " +
                                          _vm._s(
                                            index === "timeline"
                                              ? "Linha do tempo"
                                              : ""
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "b-card",
                          { staticClass: "mb-0" },
                          _vm._l(tag_category, function (tag, i) {
                            return _c(
                              "b-badge",
                              {
                                key: i,
                                staticStyle: {
                                  "margin-right": "1px",
                                  "font-size": "1rem",
                                },
                                attrs: { pill: "", variant: "light-primary" },
                              },
                              [_vm._v(" " + _vm._s(tag.title) + " ")]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }