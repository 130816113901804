<template>
  <div>
    <b-card class="mb-1">
      <form class="m-0" ref="formFilter" @submit.prevent="submitFilter">
        <div class="form-row">
          <b-col
            cols="6"
            md="3"
            class="d-none d-sm-block align-items-center justify-content-left"
          >
            <div class="form-group mb-md-0">
              <label for="">Tag Concurso</label>
              <v-select
                ref="selectConcurso"
                label="title"
                item-text="title"
                item-value="code"
                v-model="filters.tag_contest"
                placeholder="Digite o título"
                :options="optionsTagConcurso"
                @search="fetchTagsCategoryConcurso"
              >
                <span
                  slot="no-options"
                  @click="$refs.selectConcurso.open = false"
                >
                  Nenhum registro encontrado
                </span>
              </v-select>
            </div>
          </b-col>
          <b-col
            cols="6"
            md="3"
            class="d-none d-sm-block align-items-center justify-content-left"
          >
            <div class="form-group mb-md-0">
              <label for="">Tag Linha do tempo</label>
              <v-select
                ref="selectLinhadoTempo"
                label="title"
                item-text="title"
                item-value="code"
                v-model="filters.tag_timeline"
                placeholder="Digite o título"
                :options="optionsTagLinhaDoTempo"
                @search="fetchTagsCategoryLinhaDoTempo"
              >
                <span
                  slot="no-options"
                  @click="$refs.selectLinhadoTempo.open = false"
                >
                  Nenhum registro encontrado
                </span>
              </v-select>
            </div>
          </b-col>

          <b-col cols="12" md="3">
            <div class="form-group mb-md-0">
              <label for="">Período de cadastro</label>
              <flat-pickr
                v-model="filters.rangeDate"
                class="form-control"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </b-col>
          <b-col cols="12" md="2">
            <div class="form-group mb-md-0">
              <label for="">Título do edital</label>
              <input v-model="filters.title" class="form-control" />
            </div>
          </b-col>
          <b-col cols="12" md="1">
            <label for="" style="visibility: hidden">Buscar</label>
            <button
              type="submit"
              :disabled="submitedFilter"
              class="btn btn-primary btn-block"
            >
              <div v-if="submitedFilter">
                <b-spinner label="Loading..." small /> Buscando...
              </div>
              <div v-else>
                <feather-icon
                  icon="SearchIcon"
                  class="cursor-pointer cursor"
                  size="16"
                />
              </div>
            </button>
          </b-col>
        </div>
      </form>
    </b-card>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-form-row class="justify-content-between">
          <b-col
            cols="4"
            md="2"
            class="d-none d-sm-block align-items-center justify-content-left"
          >
            <v-select
              label="title"
              required
              v-model="perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </b-col>
          <b-col cols="12" md="4" offset="md-4">
            <b-form-input
              type="search"
              class="d-inline-block"
              placeholder="Pesquisar..."
              id="filterInput"
              v-model="filter"
            />
          </b-col>
        </b-form-row>
      </div>

      <b-table
        sticky-header
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(title)="data">
          <a
            :title="data.item.title"
            :href="data.item.url"
            target="_blank"
            rel="noopener noreferrer"
          >
            <feather-icon icon="ExternalLinkIcon" size="13" />
            {{ data.item.title }}
          </a>
        </template>

        <template #cell(classes)="data">
          <b-badge variant="light-dark" class="text-capitalize">
            {{ data.item.classes }}
          </b-badge>
        </template>

        <template #cell(extension)="data">
          <i
            class="bi"
            style="font-size: 1.4rem"
            :class="`bi-filetype-${data.item.extension.toLowerCase()}`"
          ></i>
        </template>

        <template #cell(created_at)="data">
          {{ data.item.created_at | datePT(true) }}
        </template>

        <template #cell(actions)="data">
          <div class="d-flex-between">
            <feather-icon
              :id="`customer-${data.item.uuid}-delete-icon`"
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              @click="confirmDelete(data.item.uuid)"
            />

            <feather-icon
              :id="`customer-${data.item.uuid}-delete-icon`"
              icon="EyeIcon"
              class="cursor-pointer cursor"
              size="16"
              @click="detailsMaterial(data.item.uuid)"
            />

            <router-link
              :id="`customer-${data.item.uuid}-edit-icon`"
              :to="{
                name: 'notices-edit',
                params: { uuid: data.item.uuid },
              }"
            >
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer cursor"
                size="16"
              />
            </router-link>
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
      />
    </b-card>

    <b-modal
      ref="modal-create-notice"
      modal-class="modal-create"
      title="Upload do Material"
      centered
      hide-footer
      size="sm"
    >
      <b-form
        ref="formCreateUser"
        enctype="multipart/form-data"
        @submit.prevent="submitUpload"
      >
        <div class="form-row">
          <div class="col-12">
            <div class="form-group">
              <label for="">
                Título
                <i class="text-danger bi bi-record-circle"></i>
              </label>
              <input
                require
                type="text"
                :class="{ 'is-invalid': $v.item.title.$error }"
                v-model="item.title"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label for="">
                Arquivo - <small>Limite máximo: 10 MB</small>
                <i class="text-danger bi bi-record-circle ml-1"></i>
              </label>
              <input
                style="padding: 0.4rem 0.5rem"
                type="file"
                id="file"
                ref="file"
                class="form-control"
                :class="{ 'is-invalid': $v.item.file.$error }"
                @change="handleFileUpload()"
              />
            </div>
          </div>
          <div class="col-12">
            <legend style="font-size: 1.2rem" class="mt-2 mb-1">Tags</legend>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="">
                Tag(s) Concurso(s)
                <i class="text-danger bi bi-record-circle"></i>
              </label>
              <v-select
                multiple
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.tags.contest"
                placeholder="Digite o título da tag"
                :options="optionsTagConcurso"
                @search="fetchTagsCategoryConcurso"
                :class="{ 'is-invalid': $v.item.tags.contest.$error }"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="">
                Tag(s) Linha do tempo
                <i class="text-danger bi bi-record-circle"></i>
              </label>
              <v-select
                multiple
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.tags.timeline"
                placeholder="Digite o título da tag"
                :options="optionsTagLinhaDoTempo"
                @search="fetchTagsCategoryLinhaDoTempo"
                :class="{ 'is-invalid': $v.item.tags.timeline.$error }"
              />
            </div>
          </div>
        </div>
        <div class="form-row justify-content-end mt-2">
          <div class="col-md-3">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              block
              @click="$bvModal.hide('modal-create-notice')"
            >
              Fechar
            </b-button>
          </div>
          <div class="col-md-4">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-2"
              type="submit"
              block
              :disabled="submited"
            >
              <div v-if="submited">
                <b-spinner small variant="light" />
              </div>
              <div v-else>Cadastrar</div>
            </b-button>
          </div>
        </div>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-details-notice"
      modal-class="modal-create"
      title="Detalhes do material"
      centered
      hide-footer
      v-show="details"
    >
      <b-row class="form-row">
        <div class="col-12">
          <div class="form-row mb-2 pb-2 border-bottom">
            <div
              v-if="
                details.extension == 'png' ||
                details.extension == 'jpg' ||
                details.extension == 'jpeg' ||
                details.extension == 'webp'
              "
              class="col-md-3 text-center"
            >
              <b-img-lazy class="my-1" :src="details.url" rounded fluid />
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="">Título</label>
                <a :href="details.url" target="_blank">
                  <feather-icon
                    icon="ExternalLinkIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  {{ details.title }}
                </a>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group mb-0">
                <strong>Size</strong>: {{ details.size }}kb -
                <strong>Host</strong>: {{ details.driver }} -
                <strong>Extensão</strong>: {{ details.extension }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <legend>Tags</legend>
          <b-tabs class="mb-0" pills fill>
            <b-tab v-for="(tag_category, index) in details.tags" :key="index">
              <template #title>
                <feather-icon icon="HashIcon" size="16" class="mr-0 mr-sm-50" />
                <span class="d-none d-sm-inline">
                  {{ index === "contest" ? "Concurso" : "" }}
                  {{ index === "timeline" ? "Linha do tempo" : "" }}
                </span>
              </template>
              <b-card class="mb-0">
                <b-badge
                  v-for="(tag, i) in tag_category"
                  :key="i"
                  pill
                  variant="light-primary"
                  style="margin-right: 1px; font-size: 1rem"
                >
                  {{ tag.title }}
                </b-badge>
              </b-card>
            </b-tab>
          </b-tabs>
        </div>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BImgLazy,
  BCard,
  BBadge,
  BAvatar,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BForm,
  BPagination,
  BFormRow,
  BSidebar,
  BFormFile,
  BSpinner,
  BModal,
  BTabs,
  BTab,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import { required } from "vuelidate/lib/validators";
import FileIcon from "@/views/components/icon-file/Icon";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

import CustomPaginateTable from "@/views/components/custom/PaginationTable";

export default {
  directives: {
    Ripple,
  },
  components: {
    BTabs,
    BImgLazy,
    BTab,
    BSidebar,
    BAvatar,
    BModal,
    BSpinner,
    BFormFile,
    BForm,
    BFormInput,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BFormRow,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    flatPickr,
    FileIcon,
    CustomPaginateTable,
  },
  validations: {
    item: {
      title: {
        required,
      },
      file: {
        required,
      },
      tags: {
        contest: {
          required,
        },
        timeline: {
          required,
        },
      },
    },
  },
  data() {
    return {
      details: "",
      item: {
        title: "",
        file: null,
        media_type_id: 4,
        tags: {
          contest: [],
          timeline: [],
        },
      },
      submited: false,
      submitedFilter: false,
      isAddNewMaterialSidebarActive: false,
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 50,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "50",
          value: 50,
        },
        {
          title: "100",
          value: 100,
        },
      ],
      dataItems: [],
      tableColumns: [
        {
          key: "title",
          label: "Título",
          sortable: false,
        },
        {
          key: "extension",
          label: "Extensão",
          sortable: false,
          class: "text-center",
          thStyle: "width: 100px",
        },
        {
          key: "created_at",
          label: "Data",
          sortable: false,
          class: "text-center",
          thStyle: "width: 200px",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: "width: 110px",
        },
      ],
      optionsTagConcurso: [],
      optionsTagLinhaDoTempo: [],
      filters: {
        tag_contest: [],
        tag_timeline: [],
        rangeDate: null,
        title: "",
        type: 4,
      },
    };
  },
  computed: {
    ...mapState("Media", ["notices", "media"]),
    ...mapState("Tag", [
      "searchTagsTema",
      "searchTagsConcurso",
      "searchTagsLinhadoTempo",
    ]),
  },
  methods: {
    submitFilter() {
      this.getData();
    },
    detailsMaterial(uuid) {
      this.details = "";
      this.$store.dispatch("Media/show", uuid).then((res) => {
        this.$bvModal.show("modal-details-notice");
        this.details = {
          title: res.title,
          url: res.url,
          extension: res.extension,
          driver: res.driver,
          size: res.size,
          tags: {
            contest: res.tags.contest,
            timeline: res.tags.timeline,
          },
        };
      });
    },
    async fetchTagsCategoryTema(term) {
      if (term.length > 2) {
        this.optionsTagTema = await this.$store.dispatch("Tag/searchTags", {
          category_id: 1,
          term: term,
        });
      }
    },
    async fetchTagsCategoryConcurso(term) {
      if (term.length > 2) {
        this.optionsTagConcurso = await this.$store.dispatch("Tag/searchTags", {
          category_id: 2,
          term: term,
        });
      }
    },
    async fetchTagsCategoryLinhaDoTempo(term) {
      if (term.length > 2) {
        this.optionsTagLinhaDoTempo = await this.$store.dispatch(
          "Tag/searchTags",
          { category_id: 3, term: term }
        );
      }
    },
    handleFileUpload() {
      this.item.file = this.$refs.file.files[0];
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name.replaceAll(" ", "-").toLowerCase();
      }
      return `${files.length} arquivos selecionados`;
    },
    submitUpload() {
      this.$v.$touch();

      const formData = new FormData();
      formData.append("file", this.item.file);
      formData.append("title", this.item.title);
      formData.append("media_type_id", this.item.media_type_id);
      formData.append(
        "tags",
        JSON.stringify({
          contest: this.item.tags.contest,
          timeline: this.item.tags.timeline,
        })
      );

      if (!this.$v.$error) {
        this.submited = true;
        this.$store
          .dispatch("Media/create", formData)
          .then(() => {
            this.notifyDefault("success");
            this.getData();
            this.$refs["modal-create-notice"].hide();
            this.clearForm();
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    async getData() {
      this.submitedFilter = true;

      let filtersSearch = {
        tag_contest: this.filters.tag_contest
          ? this.filters.tag_contest.code
          : "",
        tag_timeline: this.filters.tag_timeline
          ? this.filters.tag_timeline.code
          : "",
        rangeDate: this.filters.rangeDate ?? "",
        title: this.filters.title ?? "",
        type: this.filters.type ?? "",
      };

      this.$store
        .dispatch("Media/allType", {
          currentPage: this.currentPage,
          perPage: this.perPage,
          filters: filtersSearch,
        })
        .then(() => {
          if (this.notices) {
            this.dataItems = this.notices.data;
            this.rowsTable = this.notices.data.length;
            this.totalRows = this.notices.meta.total;
            this.currentPage = this.notices.meta.current_page;
          }
        })
        .finally(() => {
          this.submitedFilter = false;
        });
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
    },
    onFiltered(filteredItems) {
      this.metaTotal = filteredItems.length;
      this.currentPage = 1;
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Tem certeza?",
        text: "Só será possível deletar o material caso não tenha nenhum vinculo com outros registros.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("Media/delete", { uuid: uuid })
            .then(() => {
              this.getData();
              this.notifyDefault("success");
            })
            .catch(() => {
              this.notifyDefault(
                "error",
                "Edital vinculado a um ou mais concursos"
              );
            });
        }
      });
    },
    clearForm() {
      this.item = {
        title: "",
        file: null,
        media_type_id: 4,
        tags: {
          contest: [],
          timeline: [],
        },
      };
    },
  },
  mounted() {
    this.getData();
    this.$root.$on("open:modal-create-notice", () => {
      this.clearForm();
      this.$refs["modal-create-notice"].show();
    });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-notice");
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
